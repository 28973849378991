/* Application Wide CSS Styles */

/* Syncfusion editors style imports */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css";
@import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";
@import "../node_modules/@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";

/* Definition of Global Variables */
:root {
  --list-style-position: outside;
}

/* Uncomment for demos so that error display does not show up. */
/* body iframe {
  display: none;
} */

.cs-message__content,
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor,
.ps__thumb-y {
  background-color: whitesmoke !important;
}

.cs-button.cs-button--send {
  color: rgb(114, 67, 221);
}

.cs-typing-indicator__text,
.cs-typing-indicator__indicator {
  color: grey;
}

.cs-typing-indicator__dot {
  background-color: grey;
}
body {
  margin: 0;
  font-family: "Proxima Nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.toolbar {
  display: flex;
  flex-direction: row;
  gap: 3px;
  flex-wrap: wrap;
}

.toolbar > * {
  min-height: 40px;
  max-height: 40px;
}

p {
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.medium,
p.medium {
  font-weight: 400;
}

.bold,
p.bold {
  font-weight: 700;
}

.boldest,
p.boldest {
  font-weight: 900;
}

/* DRAG AND DROP (LISTVIEW) STYLING */

.dropLine {
  border: 1px dashed #bdbdbd !important;
}

/* EDITOR STYLING */

.editor-header,
.editor-footer {
  /* min-height: 100px; */
  background-color: #ffffff;
  opacity: 0.5;
  width: 100%;
  max-width: var(--lexical-doc-width, 100%);
  margin: 0 auto;
  resize: none;
  font-size: var(--lexical-doc-fontSize, 11pt);
  position: relative;
  white-space: break-spaces !important;
  /* tab-size: 1; */
  line-height: normal;
  outline: 0;
  caret-color: rgb(5, 5, 5);
}

.editor-header {
  margin-top: 1rem;
}
.editor-footer {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.editor-container {
  position: relative;
  line-height: normal;
  text-align: left;
  width: var(--lexical-doc-width, 100%);
  max-width: 780px;
  background-color: #ffffff;
  padding: 0;
  margin: 0 0 50px;
}

@media only screen and (max-width: 900px) {
  .editor-container {
    width: 680px;
  }
}
@media only screen and (max-width: 800px) {
  .editor-container {
    width: 600px;
  }
}
@media only screen and (max-width: 700px) {
  .editor-container {
    width: 460px;
  }
}
@media only screen and (max-width: 500px) {
  .editor-container {
    width: 360px;
  }
}
@media only screen and (max-width: 400px) {
  .editor-container {
    width: 320px;
  }
}

.editor-container {
  font-family: "Proxima Nova", sans-serif;
}

.editor-input {
  min-height: 450px;
  max-width: var(--lexical-doc-width);
  font-size: var(--lexical-doc-fontSize, 12px);
  font-family: var(--lexical-doc-font);
  margin: 0 auto;
  resize: none;
  position: relative;
  white-space: break-spaces !important;
  /* tab-size: 1; */
  line-height: normal;
  outline: 0;
  caret-color: #000000;
  cursor: auto;
}

.playbook-drawer-editor-input * {
  font-size: 100% !important;
}

.editor-paragraph {
  margin: 0;
  padding: 0;
  position: relative;
}

:is(h1, h2, h3, h4, h5, h6) {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: unset;
}

.editor-heading-h1 {
  margin: 0;
  padding: 0;
}

.editor-heading-h2 {
  margin: 0;
  padding: 0;
}

/* Editor Text Styles */
/* 
   We need these classes in order for the text formatting to work.
   TODO: Consider moving this more closely to the editor, so that it
   is more visible.
*/
.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

/* CROSS REFERENCES */

a.cross-ref:focus,
a.cross-ref:hover {
  cursor: pointer;
}

.cross-ref:hover,
.cross-ref:focus {
  background-color: lightgray;
}

/* COMMENT PLUGIN */

.CommentPlugin_AddCommentBox {
  display: block;
  position: fixed;
  border-radius: 20px;
  background-color: white;
  width: 40px;
  height: 60px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.CommentPlugin_AddCommentBox_button {
  border-radius: 20px;
  border: 0;
  background: none;
  width: 40px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.CommentPlugin_AddCommentBox_button:hover {
  background-color: #f6f6f6;
}

.CommentPlugin_CommentInputBox {
  display: block;
  position: fixed;
  width: 260px;
  min-height: 80px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 24px 0;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  z-index: 600;
  animation: show-input-box 0.4s ease;
}

.CommentPlugin_CommentInputBox::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0.5em;
  right: -1em;
  top: 0;
  left: calc(50% + 0.25em);
  box-sizing: border-box;
  border: 0.5em solid;
  border-color: transparent transparent #7243dd #7243dd;
  transform-origin: 0 0;
  transform: rotate(135deg);
  /*box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);*/
  box-shadow: rgba(0, 0, 0, 0.05) -3px 3px 24px 0;
}

@keyframes show-input-box {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.CommentPlugin_CommentInputBox_Buttons {
  display: flex;
  flex-direction: row;
  padding: 0 10px 10px 10px;
  gap: 5px;
}

/*
  justify-content: flex-end;


.CommentPlugin_CommentInputBox_Button {
  flex: 1;
}

.CommentPlugin_CommentInputBox_Button.primary {
  background-color: rgb(66, 135, 245);
  font-weight: bold;
  color: #fff;
}

.CommentPlugin_CommentInputBox_Button.primary:hover {
  background-color: rgb(53, 114, 211);
}

.CommentPlugin_CommentInputBox_Button[disabled] {
  background-color: #eee;
  opacity: 0.5;
  cursor: not-allowed;
  font-weight: normal;
  color: #444;
}

.CommentPlugin_CommentInputBox_Button[disabled]:hover {
  opacity: 0.5;
  background-color: #eee;
}*/

.CommentPlugin_CommentInputBox_EditorContainer {
  position: relative;
  margin: 0;
  border-radius: 5px;
}

/*
.CommentPlugin_CommentInputBox_Editor {
  position: relative;
  border: 1px solid #ccc;
  background-color: #ff0000;
  border-radius: 5px;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  display: block;
  padding: 9px 10px 10px 9px;
  min-height: 80px;
}

.CommentPlugin_CommentInputBox_Editor:focus {
  outline: 1px solid rgb(66, 135, 245);
}*/

.CommentPlugin_ShowCommentsButton {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #ddd;
  border-radius: 10px;
}

/*.CommentPlugin_ShowCommentsButton:hover i.comments {
  opacity: 1;
}*/

.CommentPlugin_ShowCommentsButton.active {
  background-color: #ccc;
}

.CommentPlugin_CommentsPanel {
  position: fixed;
  right: 0;
  width: 320px;
  height: calc(100% - 88px);
  top: 88px;
  background-color: #fff;
  border-top-left-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: show-comments 0.2s ease;
  z-index: 25;
}

@keyframes show-comments {
  0% {
    opacity: 0;
    transform: translateX(320px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.CommentPlugin_CommentsPanel_Heading {
  padding-left: 15px;
  padding-top: 10px;
  margin: 0;
  height: 34px;
  border-bottom: 1px solid #eee;
  font-size: 20px;
  display: block;
  width: 100%;
  color: #444;
  overflow: hidden;
}

.CommentPlugin_CommentsPanel_Footer {
  border-top: 1px solid #eee;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.CommentPlugin_CommentsPanel_Editor {
  position: relative;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 5px;
  font-size: 15px;
  caret-color: rgb(5, 5, 5);
  display: block;
  padding: 9px 10px 10px 9px;
  min-height: 20px;
}

.CommentPlugin_CommentsPanel_Editor::before {
  content: "";
  width: 30px;
  height: 20px;
  float: right;
}

.CommentPlugin_CommentsPanel_SendButton {
  position: absolute;
  right: 10px;
  top: 8px;
  background: none;
}

.CommentPlugin_CommentsPanel_SendButton:hover {
  background: none;
}

/*
.CommentPlugin_CommentsPanel_SendButton:hover i.send {
  opacity: 1;
  filter: invert(45%) sepia(98%) saturate(2299%) hue-rotate(201deg)
    brightness(100%) contrast(92%);
}

.CommentPlugin_CommentsPanel_SendButton[disabled] i.send {
  opacity: 0.3;
}

.CommentPlugin_CommentsPanel_SendButton:hover[disabled] i.send {
  opacity: 0.3;
  filter: none;
}*/

.CommentPlugin_CommentsPanel_Empty {
  color: #777;
  font-size: 15px;
  text-align: center;
  position: absolute;
  top: calc(50% - 15px);
  margin: 0;
  padding: 0;
  width: 100%;
}

.CommentPlugin_CommentsPanel_List {
  padding: 0;
  list-style-type: none;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 45px;
  overflow-y: auto;
}

.CommentPlugin_CommentsPanel_List_Comment {
  padding: 15px 0 15px 15px;
  margin: 0;
  font-size: 14px;
  position: relative;
  transition: all 0.2s linear;
}

.CommentPlugin_CommentsPanel_List_Thread.active,
.CommentPlugin_CommentsPanel_List_Comment:hover {
  background-color: inherit;
}

.CommentPlugin_CommentsPanel_List_Comment p {
  margin: 0;
  color: #444;
}

.CommentPlugin_CommentsPanel_List_Details {
  color: #444;
  padding-bottom: 5px;
  vertical-align: top;
}

.CommentPlugin_CommentsPanel_List_Comment_Author {
  font-weight: bold;
  padding-right: 5px;
}

.CommentPlugin_CommentsPanel_List_Comment_Time {
  color: #999;
}

.CommentPlugin_CommentsPanel_List_Thread {
  padding: 0 0 0 0;
  margin: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  transition: all 0.2s linear;
  border-left: 0 solid #eee;
}

.CommentPlugin_CommentsPanel_List_Thread:first-child,
.CommentPlugin_CommentsPanel_List_Thread {
  border-top: none;
}

.CommentPlugin_CommentsPanel_List_Thread.interactive {
  cursor: pointer;
}

.CommentPlugin_CommentsPanel_List_Thread.interactive:hover {
  background-color: #fafafa;
}

.CommentPlugin_CommentsPanel_List_Thread.active {
  background-color: #fafafa;
  border-left: 15px solid #eee;
  cursor: inherit;
}

.CommentPlugin_CommentsPanel_List_Thread_Quote {
  padding-top: 10px;
  margin: 0 10px 0 10px;
  color: #ccc;
  display: block;
}

.CommentPlugin_CommentsPanel_List_Thread_Quote span {
  color: #222;
  background-color: rgba(255, 212, 0, 0.4);
  padding: 1px;
  line-height: 1.4;
  display: inline;
  font-weight: bold;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments {
  padding-left: 10px;
  list-style-type: none;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments,
.CommentPlugin_CommentsPanel_List_Comment:first-child {
  border: none;
  margin-left: 0;
  padding-left: 5px;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments,
.CommentPlugin_CommentsPanel_List_Comment:first-child.CommentPlugin_CommentsPanel_List_Comment:last-child {
  padding-bottom: 5px;
}

.CommentPlugin_CommentsPanel_List_Thread_Comments,
.CommentPlugin_CommentsPanel_List_Comment {
  padding-left: 10px;
  border-left: 5px solid #eee;
  margin-left: 5px;
}

.CommentPlugin_CommentsPanel_List_Thread_Editor {
  position: relative;
  padding-top: 1px;
}

.CommentPlugin_CommentsPanel_List_DeleteButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  opacity: 0;
}

.CommentPlugin_CommentsPanel_List_Comment:hover,
.CommentPlugin_CommentsPanel_List_DeleteButton {
  opacity: 0.5;
}

.CommentPlugin_CommentsPanel_List_DeleteButton:hover {
  background-color: transparent;
  opacity: 1;
  filter: invert(45%) sepia(98%) saturate(2299%) hue-rotate(201deg)
    brightness(100%) contrast(92%);
}

/* COMMENT EDITOR THEME */

.Commentparagraph {
  margin: 0;
  position: relative;
}

/* COMMENT PLAIN TEXT EDITOR */
.ContentEditable__root {
  height: 100%;
  border: 0;
  resize: none;
  cursor: text;
  font-size: 15px;
  display: block;
  position: relative;
  /* tab-size: 1; */
  outline: 0;
  padding: 15px 20px 15px 20px;
  overflow: auto;
}

.ContentEditable__root + .Placeholder__root {
  padding-top: 10px;
}

.ContentEditable__rootReply {
  /*height: 80px;*/
  min-height: 75px;
  border: 0;
  resize: none;
  cursor: text;
  font-size: 14px;
  display: block;
  position: relative;
  /* tab-size: 1; */
  outline: 0;
  padding: 0 20px 0 20px;
  overflow: auto;
}

.Placeholder__root {
  font-size: 15px;
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 5px;
  left: 20px;
  display: inline-block;
  pointer-events: none;
  user-select: text;
  white-space: pre-wrap;
  word-break: break-word;
}

/* MARK STYLING */
mark {
  background-color: var(--highlight-color, yellow);
}

mark.__invisible {
  background: none !important;
  border-bottom: none !important;
  padding-bottom: 2px;
}

.__markComment {
  background: none;
}

.__markComment * {
  background: #eeeeee;
  border-bottom: 2px solid #bdbdbd;
  padding-bottom: 2px;
}

.__markComment.__resolved * {
  background: none;
  border-bottom: none;
  padding-bottom: 2px;
}

.__markComment.selected * {
  background: var(--party-color-lighter);
  border-bottom: 2px solid var(--party-color);
}

.__markCommentInternal {
  /*background: rgba(247, 140, 0, 0.15);*/
  background: rgba(0, 255, 255, 1);
  /*border-bottom: 2px solid rgba(247, 140, 0, 0.4);
  padding-bottom: 2px;*/
}

.__markCommentInternal.selected {
  /*background: rgba(255, 212, 0, 0.5);*/
  background: rgba(0, 255, 255, 1);
  /*border-bottom: 2px dotted rgba(247, 140, 0, 1);*/
}

.__markApproval {
  /*background: rgba(0, 250, 230, 0.15);
  border-bottom: 2px solid rgba(0, 250, 230, 0.4);
  padding-bottom: 2px;*/
  background: rgba(0, 255, 255, 1);
}

.__markApproval.selected {
  /*
  background: rgba(0, 250, 230, 0.4);
  border-bottom: 2px solid rgba(0, 250, 230, 1);*/
  background: rgba(0, 255, 255, 1);
}

.__markParam {
  background: rgba(75, 140, 245, 0.15);
  border-bottom: 2px solid rgba(75, 140, 245, 0.3);
  padding-bottom: 2px;
}

.__markParam.selected {
  background: rgba(75, 140, 245, 0.2);
  border-bottom: 2px solid rgba(75, 140, 245, 0.5);
}

.__markOverlapCPA {
  background: rgba(255, 212, 0, 0.3);
  border-bottom: 2px solid rgba(75, 140, 245, 0.7);
}

.__markOverlapC * {
  background: #eeeeee;
  border-bottom: 2px solid #bdbdbd;
  padding-bottom: 2px;
}

.__markOverlapC.__resolved * {
  background: none;
  border-bottom: none;
  padding-bottom: 2px;
}

.__markOverlapC.selected * {
  background: var(--party-color-lighter);
  border-bottom: 2px solid var(--party-color);
}

:is(.__markOverlapCPA.selected, .__markOverlapC.selected) {
  background: rgba(255, 212, 0, 0.7);
}

.__markOverlapIC {
  background: rgba(247, 140, 0, 0.3);
  border-bottom: 2px solid rgba(247, 140, 0, 0.7);
}

.__markOverlapIC.selected {
  background: rgba(247, 140, 0, 0.7);
  border-bottom: 2px solid rgba(247, 140, 0, 0.7);
}

.__markOverlapAP {
  background: rgba(0, 250, 230, 0.3);
  border-bottom: 2px solid rgba(0, 250, 230, 0.7);
}

.__markOverlapAP.selected {
  background: rgba(0, 250, 230, 0.7);
  border-bottom: 2px solid rgba(0, 250, 230, 0.7);
}

.__markOverlapPA {
  background: #eeeeee;
  border-bottom: 2px solid #bdbdbd;
  /* background: rgba(75, 140, 245, 0.3);
  border-bottom: 2px solid rgba(75, 140, 245, 0.7); */
}

.__markOverlapPA.selected {
  /* background: rgba(75, 140, 245, 0.7);
  border-bottom: 2px solid rgba(75, 140, 245, 0.7); */
  background: darkgray;
  border-bottom: 2px solid grey;
}

/*
.CommentPlugin_CommentsPanel_List_DeleteButton i.delete {
  background-size: contain;
  position: absolute;
  left: 5px;
  top: 5px;
  height: 15px;
  width: 15px;
  vertical-align: -10px;
  background-image: url(../images/icons/trash3.svg);
  transition: opacity 0.2s linear;
}*/

#mentions-typeahead {
  position: fixed;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 24px 0;
  border-radius: 0;
  z-index: 1500;
}

#mentions-typeahead ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 0;
}

#mentions-typeahead ul li {
  padding: 10px 20px;
  margin: 0;
  min-width: 180px;
  font-size: 15px;
  outline: none;
  cursor: pointer;
  border-radius: 0;
}

#mentions-typeahead ul li.selected {
  background: #f5f5f5;
  color: #000;
  font-weight: 600;
}

.MuiColorInput-PopoverBody {
  padding: 5px;
}

.ol1 {
  padding: 0;
  margin: 0;
  list-style-position: var(--list-style-position);
}
.ol2 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: var(--list-style-position);
}
.ol3 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: var(--list-style-position);
}
.ol4 {
  padding: 0;
  margin: 0;
  list-style-type: auto;
  list-style-position: var(--list-style-position);
}
.ol5 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: var(--list-style-position);
}
.ol6 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: var(--list-style-position);
}
.ol7 {
  padding: 0;
  margin: 0;
  list-style-type: auto;
  list-style-position: var(--list-style-position);
}
.ol8 {
  padding: 0;
  margin: 0;
  list-style-type: lower-alpha;
  list-style-position: var(--list-style-position);
}
.ol9 {
  padding: 0;
  margin: 0;
  list-style-type: upper-roman;
  list-style-position: var(--list-style-position);
}

.ul {
  padding: 0;
  margin: 0;
  list-style-position: var(--list-style-position);
}

.rtl.listItem {
  margin-left: 0px;
}

.listItem > h1,
.listItem > h2,
.listItem > h3,
.listItem > h4,
.listItem > h5,
.listItem > h6 {
  display: inline;
  margin: 0;
  font-size: inherit;
  color: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.listItemChecked,
.listItemUnchecked {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}
.listItemChecked {
  text-decoration: line-through;
}
.listItemUnchecked:before,
.listItemChecked:before {
  content: "";
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}
.listItemUnchecked[dir="rtl"]:before,
.listItemChecked[dir="rtl"]:before {
  left: auto;
  right: 0;
}
.listItemUnchecked:focus:before,
.listItemChecked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}
.listItemUnchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}
.listItemChecked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}
.listItemChecked:after {
  content: "";
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}
.nestedListItem {
  list-style-type: none;
}
.nestedListItem:before,
.nestedListItem:after {
  display: none;
}

.listItemNoMarker::marker {
  content: "";
}

.listItem::marker {
  font-weight: var(--marker-font-weight, inherit);
  font-size: var(--marker-font-size, inherit);
  font-family: var(--marker-font-family, inherit);
  color: var(--marker-color, inherit);
  font-style: var(--marker-font-style, inherit);
}

.swiper-slide {
  width: auto !important;
}

.fabNewButton {
  left: 125px !important;
  top: 100px !important;
  right: unset !important;
}

@media only screen and (min-width: 1441px) {
  .fabNewButton {
    left: 125px !important;
    top: 100px !important;
    right: unset !important;

    /*October cleanup*/
    /*right: 25vw !important;*/
    /*bottom: 25vh !important;*/
  }
}

.tab {
  display: inline;
  padding-left: var(--lexical-indent-base-value);
}

/**
 * TABLES
 * these are styles related to tables
 */

.editor-table {
  table-layout: fixed;
  border-collapse: collapse;
  margin: 5px 0;
  background-color: var(--table-background-color);
}

.editor-table.table-width-min {
  width: auto;
}

.editor-table.table-width-max {
  width: 100%;
}

.editor-table.rtl,
.editor-table.rtl * {
  direction: rtl;
}

.editor-table.ltr,
.editor-table.ltr * {
  direction: ltr;
}

.editor-table td {
  background-color: var(--cell-background-color, white);
  padding: 3pt;
}

.editor-table th {
  padding: 3pt;
  background-color: var(--header-background-color, lightgray);
}

.editor-table.vertical td,
.editor-table.vertical th {
  border-left: var(--vertical-lineStyle) var(--vertical-lineWidth)
    var(--vertical-color);
  border-right: var(--vertical-lineStyle) var(--vertical-lineWidth)
    var(--vertical-color);
}

.editor-table.horizontal td,
.editor-table.horizontal th {
  border-top: var(--horizontal-lineStyle) var(--horizontal-lineWidth)
    var(--horizontal-color);
  border-bottom: var(--horizontal-lineStyle) var(--horizontal-lineWidth)
    var(--horizontal-color);
}

.editor-table.left-clear,
.editor-table.bottom-clear tr > td:first-of-type {
  border-left: none;
}

.editor-table.right-clear,
.editor-table.bottom-clear tr > td:last-of-type {
  border-right: none;
}

.editor-table.top-clear,
.editor-table.bottom-clear tr:first-of-type > td {
  border-top: none;
}

.editor-table.bottom-clear,
.editor-table.bottom-clear tr:last-of-type > td {
  border-bottom: none;
}

/* Tables Menu */
.dropdown {
  z-index: 10;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-height: 40px;
  background-color: #fff;
}

.dropdown .item {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #050505;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 8px;
  border: 0;
  max-width: 250px;
  min-width: 100px;
}

.dropdown .item.fontsize-item,
.dropdown .item.fontsize-item .text {
  min-width: unset;
}

.dropdown .item .active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.dropdown .item:first-child {
  margin-top: 8px;
}

.dropdown .item:last-child {
  margin-bottom: 8px;
}

.dropdown .item:hover {
  background-color: #eee;
}

.dropdown .item .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.dropdown .item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 12px;
  line-height: 16px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.dropdown .divider {
  width: auto;
  background-color: #eee;
  margin: 4px 8px;
  height: 1px;
}

@media screen and (max-width: 1100px) {
  .dropdown-button-text {
    display: none !important;
  }
  .font-size .dropdown-button-text {
    display: flex !important;
  }
  .code-language .dropdown-button-text {
    display: flex !important;
  }
}

.table-cell-action-button-container {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
}

.table-cell-action-button {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  position: relative;
  border-radius: 15px;
  color: #222;
  display: inline-block;
  background-color: rgb(228, 228, 228);
  cursor: pointer;
}

i.chevron-down {
  background-color: transparent;
  background-size: contain;
  display: inline-block;
  height: 10px;
  width: 10px;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Feather-arrows-chevron-down.svg/480px-Feather-arrows-chevron-down.svg.png");
}

.MuiMenuItem-root.menu-disabled {
  cursor: not-allowed;
  color: grey;
}

.MuiMenuItem-root.menu-disabled:hover {
  background-color: inherit;
}

.dashboard-uploader {
  display: inline-block;
  width: 100%;
  min-height: 100vh;
  cursor: default !important;
  border: none !important;
}

.dashboard-uploader svg,
.dashboard-uploader svg path {
  color: inherit !important;
  fill: currentColor !important;
}

.version_upload {
  width: 100%;
}

/* Hide Page Number */
.editor-footer .editor-paragraph [href^=" PAGE "] {
  display: none;
}

/* List marker styles */
/* The --list-item-indentation controls the space between the start of the grid and the list marker. */
/* The --list-item-marker-gap controls the space between the list marker and the contents of the list. */
/* .editor-container li.listItem {
  margin-left: calc(
    var(--list-item-indentation) + var(--list-item-level-indentation)
  );
  padding-left: var(--list-item-marker-gap);
} */

/* The --list-item-marker-gap controls the space between the list marker and the contents of the list. */
.editor-input li::before {
  content: var(--before-content, "\2022   ");
  font-size: var(--lexical-doc-fontSize);
  font-size: var(--list-item-marker-font-size);
  /* margin-right: var(--list-item-marker-gap); */
  font-weight: var(--list-item-marker-font-weight, initial);
}

/* Table of Contents (TOC) styles */
.toc-first-element {
  font-size: 9pt;
  font-style: italic;
}
.toc-element {
  display: none;
}

li.listItemNoMarker::before {
  display: none;
}

/* Loading backdrop for chat container. */
.cs-overlay {
  background-color: #7243dd !important;
  opacity: 0.4;
}

/* Used in the `createDOM` method of the ImageNode class for images with "Square" text wrapping style. */
.square-wrapping-style-image-container > img {
  width: var(--square-wrapping-style-image-width, 10px) !important;
}

.mark-transparent-highlight > mark {
  background-color: transparent !important;
}

/* Change the background color of the area behind the PDF page */
.e-pv-page-container {
  background-color: rgba(114, 67, 221, 0.15);
}
